import { Image } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';
import { Box, makeStyles, Theme } from '@material-ui/core';
import Link from 'next/link';
import React from 'react';

import { BrandSliderItem } from '~/types/slider';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '190px',
    height: '115px',
    border: '1px solid',
    '& a': {
      cursor: 'pointer',
    },
    borderRadius: '2px',
  },
  item: {
    margin: theme.spacing(2, 3),
    width: '135px',
    height: '80px',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    width: '80px',
    height: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

interface IProps {
  item: BrandSliderItem;
}

const MaybeLink: React.FC<{ url: string }> = ({ url, children }) => {
  const trimmedUrl = (url || '').trim();

  // when url was mandatory, we used '#' as a placeholder, so treat # as empty
  if (!trimmedUrl || trimmedUrl === '#') {
    return <>{children}</>;
  }

  const isExternal =
    trimmedUrl?.startsWith('http') && !trimmedUrl?.includes('finn.com');

  return (
    <Link href={trimmedUrl}>
      <a
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noreferrer' : undefined}
      >
        {children}
      </a>
    </Link>
  );
};

const BrandItem: React.FunctionComponent<IProps> = ({ item }) => {
  const classes = useStyles();

  return (
    <MaybeLink url={item?.url}>
      <Box
        className={cn(
          classes.wrapper,
          '!border-pearl hover:!shadow-highlight !bg-white'
        )}
      >
        <Box className={classes.item}>
          <Image
            variant="lazy-loading"
            className={classes.image}
            dataSrc={item?.image}
            alt={item?.key || 'Brand logo'}
          />
        </Box>
      </Box>
    </MaybeLink>
  );
};

export default BrandItem;
